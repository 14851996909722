.border-b-incomplete {
    @apply relative;

    &::after {
        @apply absolute bottom-0;
        @apply w-3/4 mx-auto h-px bg-gray;

        content: "";
    }
}

.border-t-incomplete {
    @apply relative;

    &::before {
        @apply absolute top-0;
        @apply w-3/4 mx-auto h-px bg-gray;

        content: "";
    }
}

.border-b-incomplete-0::after,
.border-t-incomplete-0::before {
    @apply w-0 h-0;
}