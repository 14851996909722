@import "./bootstrap/bootstrap";

@layer base {
    @import "base/base";
}

@layer components {
    @import "components/components";
    @import "layout/layout";
}

@layer utilities {
    @variants responsive {
        @import "utilities/utilities";
    }
}
